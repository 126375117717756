<template>
  <div class="weverse-simul">
    <h1>렌탈 유동화 시뮬레이션</h1>
    <!-- 입력 폼 -->
    <div class="input-form">
      <label>
        매월 판매액 (억원):
        <input v-model.number="monthlySales" type="number" step="0.1">
      </label>
      <label>
        판매액 증가율 (%/월):
        <input v-model.number="salesGrowthRate" type="number" step="0.1">
      </label>
      <label>
        유동화 LTV (%):
        <input v-model.number="ltv" type="number" min="0" max="100">
      </label>
      <label>
        유동화금리 (%):
        <input v-model.number="interestRate" type="number" step="0.1">
      </label>
      <label>
        렌탈개월수:
        <input v-model.number="rentalMonths" type="number" min="1">
      </label>
      <label>
        시뮬레이션 기간 (개월):
        <input v-model.number="simulationPeriod" type="number" min="1">
      </label>
      <button @click="calculateSimulation">시뮬레이션 계산</button>
    </div>

    <!-- 결과 테이블 -->
    <div v-if="simulationResults.length > 0" class="results-table">
      <table>
        <thead>
          <tr>
            <th>항목 \ 월</th>
            <th v-for="(_, index) in simulationResults" :key="index">
              {{ index + 1 }}월
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>판매액 (억원)</td>
            <td v-for="(result, index) in simulationResults" :key="'sales-'+index">
              {{ result.sales.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td>유동화금액 (억원)</td>
            <td v-for="(result, index) in simulationResults" :key="'liquidation-'+index">
              {{ result.liquidation.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td>고객 입금액 (억원)</td>
            <td v-for="(result, index) in simulationResults" :key="'customerPayment-'+index">
              {{ result.customerPayment.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td>유동화 원리금 상환 (억원)</td>
            <td v-for="(result, index) in simulationResults" :key="'monthlyPayment-'+index">
              {{ result.monthlyPayment.toFixed(2) }}
            </td>
          </tr>
          <!-- <tr>
            <td>유동화 이자 (억원)</td>
            <td v-for="(result, index) in simulationResults" :key="'interest-'+index">
              {{ result.interest.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td>당월매출대비 유동화이자 (%)</td>
            <td v-for="(result, index) in simulationResults" :key="'interestRatio-'+index">
              {{ (result.interest / result.sales * 100).toFixed(2) }}
            </td>
          </tr> -->
          <tr>
            <td>회사 반환금 (억원)</td>
            <td v-for="(result, index) in simulationResults" :key="'companyReturn-'+index">
              {{ (result.customerPayment - result.monthlyPayment).toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td>회사 현금 입금 (억원)</td>
            <td v-for="(result, index) in simulationResults" :key="'companyCashIn-'+index">
              {{ (result.liquidation + (result.customerPayment - result.monthlyPayment)).toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td>현금입금/판매액 (%)</td>
            <td v-for="(result, index) in simulationResults" :key="'cashInRatio-'+index">
              {{ ((result.liquidation + (result.customerPayment - result.monthlyPayment)) / result.sales * 100).toFixed(2) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      monthlySales: 100,
      ltv: 60,
      interestRate: 7,
      rentalMonths: 24,
      simulationPeriod: 24,
      salesGrowthRate: 3,
      simulationResults: []
    }
  },
  methods: {
    calculateSimulation() {
      this.simulationResults = []
      let currentSales = this.monthlySales * 100 // 억원을 원으로 변환
      const monthlyGrowthRate = 1 + (this.salesGrowthRate / 100)

      let customerPayments = new Array(this.simulationPeriod + 1).fill(0)
      let liquidationPayments = new Array(this.simulationPeriod + 1).fill(0)
      let interestPayments = new Array(this.simulationPeriod + 1).fill(0)

      for (let i = 0; i < this.simulationPeriod; i++) {
        const liquidationAmount = currentSales * (this.ltv / 100)
        const monthlyCustomerPayment = currentSales / this.rentalMonths
        const monthlyRate = this.interestRate / 12 / 100
        const monthlyLiquidationPayment = liquidationAmount * (monthlyRate * Math.pow(1 + monthlyRate, this.rentalMonths)) / (Math.pow(1 + monthlyRate, this.rentalMonths) - 1)

        let remainingPrincipal = liquidationAmount

        for (let j = 1; j <= this.rentalMonths && i + j <= this.simulationPeriod; j++) {
          customerPayments[i + j] += monthlyCustomerPayment
          liquidationPayments[i + j] += monthlyLiquidationPayment

          const interestPayment = remainingPrincipal * monthlyRate
          interestPayments[i + j] += interestPayment
          remainingPrincipal -= (monthlyLiquidationPayment - interestPayment)
        }

        let customerPaymentSum = customerPayments[i]
        let monthlyPaymentSum = liquidationPayments[i]
        let interestSum = interestPayments[i]
        let companyCashIn = liquidationAmount + customerPaymentSum - monthlyPaymentSum

        this.simulationResults.push({
          sales: currentSales / 100,
          liquidation: liquidationAmount / 100,
          customerPayment: customerPaymentSum / 100,
          monthlyPayment: monthlyPaymentSum / 100,
          interest: interestSum / 100,
          interestRatio: (interestSum / currentSales) * 100,
          companyCashIn: companyCashIn / 100,
          cashInRatio: (companyCashIn / currentSales) * 100
        })

        currentSales *= monthlyGrowthRate
      }
    }
  }
}
</script>

<style scoped>
.weverse-simul {
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
}

.input-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.input-form label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.input-form input {
  margin-top: 5px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.results-table {
  overflow-x: auto;
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 100px;
}

table {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 12px;
}

th, td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: right;
}

th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 10;
}

th:first-child, td:first-child {
  position: sticky;
  left: 0;
  background-color: #f2f2f2;
  z-index: 20;
}

@media screen and (max-width: 600px) {
  .weverse-simul {
    padding: 5px;
  }

  .input-form {
    gap: 12px;
  }

  .input-form label {
    font-size: 12px;
  }

  .input-form input {
    padding: 6px;
  }

  button {
    padding: 8px 12px;
    font-size: 14px;
  }

  table {
    font-size: 12px;
  }

  th, td {
    padding: 8px;
  }
}
</style>
